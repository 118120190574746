.formNav {
    display: flex;
    width: 60%;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
    background-color: #212529;
    justify-content: center;
    z-index: 1;
    border: 2px solid #f5bfcd;
    color: white;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    border-radius: 25px;
    background-image: linear-gradient(to right, #20deff, #65fd37);
    background-color: #212529;
}

.nav-pills .nav-link {
    transition: background-image .5s;
}

.nav-link:hover {
    color: rgb(172, 172, 172);
}

.nav-link {
    color: white;
}

#select {
    display: none;
    width: 80%;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
    background-color: #212529;
    justify-content: center;
    text-align: center;
    z-index: 1;
    border: 2px solid #f5bfcd;
    color: white;
}

@media only screen and (max-width: 770px) {
    #select {
        display: block;
    }

    .nav {
        display: none;
    }
}