#twitter-widget-holder {
    height: 99%;
    width: 97%;
    outline: black solid 2px;
}

.mainLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.container-fluid {
    max-width: 100%;
}

html {
    overflow-x: hidden;
    overflow-y: visible;
}

.twitch-video {
    width: 60%;
    aspect-ratio: 16/9;
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-bottom: 2.5%;
}

.card {
    width: 95%;
    aspect-ratio: 16/9;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 1rem;
    overflow: hidden;
    background-color: transparent;
}

.card .largeSection {
    width: 70%;
    height: 100%;
    float: right;
    background-color: #f5bfcd;
    overflow: hidden;
}

.card .smallSection {
    width: 30%;
    height: 100%;
    float: left;
    background-color: #fadf9a;
    overflow: hidden;
}

.socialButton {
    margin-top: 0.5%;
    width: 49.5%;
    aspect-ratio: 20/1;
    color: #fff;
    background-color: #13110f;
    text-align: center;
    line-height: 200%;
    font-size: 0.8vw;
    text-decoration: none;
    transition: all 0.25s;
}

.socialButton:hover {
    color: #acacac;
}

#largeBooton {
    aspect-ratio: 40/1;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

footer .text-small {
    font-size: 0.9rem;
}

footer a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

footer a:hover, a:focus {
    text-decoration: none;
    color: #b1b1b1
}

footer .form-control {
    background: #212529;
    border-color: #545454;
}

footer .form-control:focus {
    background: #212529;
}

.colourBorder {
    border: 2px solid #f5bfcd;
}

.colourBorderYellow {
    border: 2px solid #fffc5a;
}

.colourBorderLeft {
    border-top: 3px solid #f5bfcd;
    border-left: 3px solid #f5bfcd;
    border-bottom: 3px solid #f5bfcd;
}

.colourBorderRight {
    border-top: 3px solid #f5bfcd;
    border-right: 3px solid #f5bfcd;
    border-bottom: 3px solid #f5bfcd;
}

.colourBorderLeftYellow {
    border-top: 3px solid #fffc5a;
    border-left: 3px solid #fffc5a;
    border-bottom: 3px solid #fffc5a;
}

.colourBorderRightYellow {
    border-top: 3px solid #fffc5a;
    border-right: 3px solid #fffc5a;
    border-bottom: 3px solid #fffc5a;
}

.funkyColourBorder {
    border-top: 3px solid #ffa6be;
    border-left: 3px solid #ffa6be;
    border-right: 3px solid #fffc5a;
    border-bottom: 3px solid #fffc5a;
}

.homeLeft {
    border-top: 3px solid #fffc5a;
    border-left: 3px solid #fffc5a;
    border-bottom: 3px solid #fffc5a;
}

.homeRight {
    border-top: 3px solid #f5bfcd;
    border-right: 3px solid #f5bfcd;
    border-bottom: 3px solid #f5bfcd;
    border-top-right-radius: 25px;
}

footer {
    padding-right: 0;
    background: #212529;
}

#footerDiv {
    margin-top: 1%;
}

.aboutMeText {
    font-size: 1.1vw;
}

.cardHeader {
    margin-top: 25%;
    font-size: 1.6vw;
}

.cardEmotes {
    margin-top: 20%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 70%
}

.cardText {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.1vw;
    width: 80%;
    margin-top: 10%;
    margin-bottom: 10%;
}

.cardButtons {
    margin-top: 20%;
}

.twoCardButton {
    padding-top: 0.937%;
    padding-bottom: 0.937%;
    width: 40%;
    font-size: 1.1vw;
}

.threeCardButton {
    padding-top: 2%;
    padding-bottom: 2%;
    width: 27%;
    font-size: 0.8vw;
}

.cardButton {
    color: #000;
    background-color: #fadf9a;
    border: 3px outset #fadf9a;
    text-align: center;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.25s
}

.cardButton:hover {
    background-color: #f3d589fa;
    color: #000;
}

.cardButton:active {
    border: 3px inset #fffc5a;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px;
}

.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
}
  
.reveal.show{
    transform: translateY(0);
    opacity: 1;
}

.pc {
    display: flex;
}

.mobile {
    display: none;
}

.aboutMeHeader {
    font-size: 3vw;
}

.nf404 {
    color: #fff;
    text-align: center;
}

.homeButton {
    display: flex;
    margin-top: 0.5%;
    width: 100%;
    color: #fff;
    background-color: #13110f;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 200%;
    font-size: 1.2vw;
    text-decoration: none;
    transition: all 0.25s;
    height:5%
}

.homeButton:hover {
    color: #b7b7b7
}

.loginModel {
    background-color: #212529;
    color: #FFF;
    text-align: center;
    border-radius: 25px;
}

.loginModelHeader h1 {
    width: 100%;
}

.loginModelHeader button {
    position: absolute;
    right: 7.5%;
    background: url(https://i.ibb.co/TwWBWRX/close-Bigger.png) center/2em auto no-repeat;
    opacity: 1;
}

#rememberMeLabel {
    margin-left: 2%;
}

.loginModelFooter {
    justify-content: center;
}

.connButtonModel {
    height: 50px;
    border-radius: 25px;
    aspect-ratio: 12/3;
}

.discordButtonModel {
    background-color: transparent;
    color: #7289da;
    font-size: 1rem;
    margin-right: 5%;
}

.twitchButtonModel {
    background-color: transparent;
    color: #b181ff;
    font-size: 1rem;
    margin-left: 3%;
    margin-right: 2.5%;
}

@media (prefers-reduced-motion: no-preference) {
    .reveal {
      transition: all 1s;
    }
}

@media only screen and (min-width: 1000px) {
    .col-4-5 { 
        width: 37.5%; 
    }

    .carouselCenter {
        top: 50%;
        transform: translateY(-50%);
    }
}

@media only screen and (max-width: 1000px) {
    html {
        overflow-x: hidden;
    }

    .col-4-5 { 
        width: 90%; 
    }   

    .mainLogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    .twitch-video {
        width: 95%;
        aspect-ratio: 16/9;
        margin-right: auto;
        margin-left: auto;
        display: block;
        margin-bottom: 5%;
    }

    .col-9 {
        width: 90%;
    }
    
    .socialButton {
        margin-top: 2%;
        width: 100%;
        aspect-ratio: 7.5/1;
        color: #fff;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        display: block;
    }

    #largeBooton {
        aspect-ratio: 7.5/1;
        border-radius: 0px;
    }

    .col-4 {
        width: 90%;
        padding: 0%;
    }

    .card {
        width: 100%;
        margin-top: 5%;
    }

    #footerDiv {
        margin-top: 5%;
    }

    .aboutMeText {
        font-size: larger;
    }

    .cardHeader {
        margin-top: 25%;
        font-size: 4.5vw;
    }

    .twoCardButton {
        font-size: 4vw;
    }
    
    .threeCardButton {
        font-size: 2.5vw;
    }

    .cardText {
        font-size: 3.5vw;
    }

    .socialButton {
        line-height: 285%;
        font-size: 4vw;
    }

    .reveal {
        opacity: 1;
        transition: none;
        transform: translateY(0);
    }

    .homeLeft {
        border-top: 3px solid #fffc5a;
        border-left: 3px solid #fffc5a;
        border-right: 3px solid #fffc5a;
        border-bottom: 3px solid #fffc5a;
        border-radius: 25px;
        margin-bottom: 5%;
    }

    .pc {
        display: none;
    }

    .mobile {
        display: flex;
    }

    .col-3 {
        width: 95%;
    }

    .textColumns {
        width: 100%;
    }

    .textColumnHeader {
        font-size: 5.5vw;
    }
    .textColumnHeader {
        font-size: 5.5vw;
    }

    .textColumnText {
        font-size: 5.5vw;
    }

    .collapse {
        margin-bottom: 5%;
    }

    .homeButton {
        display: flex;
        margin-top: 0.5%;
        width: 95%;
        color: #fff;
        background-color: #13110f;
        text-align: center;
        align-items: center;
        justify-content: center;
        line-height: 200%;
        font-size: 5vw;
        text-decoration: none;
        transition: all 0.25s;
    }
}