.twitchPlayer {
    width: 55%;
    aspect-ratio: 16/9;
    padding: 0%;
}

.twitchChat {
    width: 20%;
    padding: 0%;
}

.vodContainer {
    width: 18%;
    padding: 0%;
    background-color: #13110f;
    overflow-y: scroll;
    aspect-ratio: 5/1;
}

.thumbnail {
    width: 15vw;
}

.videoButton {
    display: flex;
    background-color: #13110f;
    border: none;
    width: 15vw;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    padding: 0%;
    color: #fff;
    font-size: 0.8vw;
}

.textColumnsButton {
    width: 95%;
    background-color: #13110f;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}

.textColumns {
    width: 95%;
    background-color: #13110f;
    border-radius: 25px;
}

.textColumnHeaderBox {
    margin-bottom: 5%;
}

.textColumnHeader {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 1.6vw;
    justify-content: center;
}

.textColumnText {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    margin-top: 3%;
    font-size: 1.1vw;
    width: 80%;
    justify-content: center;
    text-align: center;
    min-height: 23.2vw;
}

.vodContainer::-webkit-scrollbar {
    width: 10px;
}

.vodContainer::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.vodContainer::-webkit-scrollbar-thumb {
    background: #888; 
}

.vodContainer::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.mobile {
    display: none;
}

.pc {
    display: flex;
}

@media only screen and (max-width: 1000px) {
    .videoButton {
        font-size: 15px;
    }

    .twitchPlayer {
        width: 90%;
        margin-bottom: 5%;
        border-right: 2px solid #ffa6be;
    }
    
    .twitchChat {
        width: 90%;
        border-left: 2px solid #fffc5a;
        margin-bottom: 5%;
        aspect-ratio: 1/1.5;
    }

    .vodContainer {
        width: 90%;
        border-left: 2px solid #fffc5a;
        margin-bottom: 5%;
        aspect-ratio: 1/1.5;
    }

    .thumbnail {
        width: 75vw;
    }

    .videoButton {
        width: 75vw;
    }

    .pc {
        display: none;
    }

    .mobile {
        display: flex;
    }

    .col-3 {
        width: 95%;
    }

    .textColumns {
        width: 100%;
    }

    .textColumnHeader {
        font-size: 5.5vw;
    }

    .textColumnText {
        font-size: 5.5vw;
    }

    .collapse {
        margin-bottom: 5%;
    }
}