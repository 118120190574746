#pricesBox {
    background-color: #13110f;
    border-radius: 25px;
    color: white;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.smallInput {
    width: 67%;
    display: inline-block;
}

#pricesBox h1 {
    margin-top: 2%;
}

.mobileMain {
    display: none;
}

@media only screen and (max-width: 770px) {
    .mobileMain {
        display: block;
    }

    #pricesBox {
        padding-left: 3%;
        padding-right: 3%;
        margin-bottom: 5%;
    }

    .pcMain {
        display: none;
    }
}