.background {
    background-color: #13110f;
    text-align: center;
    border-radius: 25px;
    margin-top: 1.5%;
}

.nameHeader {
    color: white;
    margin-top: 5%;
    font-size: 2.1vi;
}

.infoText {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    margin-top: 3%;
    font-size: 1.1vi;
    width: 80%;
    align-items: center;
}

.carouselStyle {
    padding-top: 2%;
    height: 98%; 
    width: 90%;
    margin-right: "auto";
    margin-left: "auto";
    display: "block";
    margin-bottom: 2%;
}

.carouselCenter {
    margin-bottom: 2%;
}

.recolouredCardButton {
    background-color: #f5bfcd;
    border-color: #f5bfcd;
    border-radius: 25px;
}

.recolouredCardButton:hover {
    background-color: #f5bfcee8;
}

.recolouredCardButton:active {
    border-color: #f5bfcd;
}

.peoplesImages {
    margin-top: 2%;
    margin-bottom: 2%;
    border-radius: 25px;
    height: 15vw;
}

.rightTeamColumn {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.leftTeamColumn {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.teamCardButton {
    font-size: 1.1vi;
    margin-top: 4%;
}

@media only screen and (max-width: 1920px) {
    .infoText {
        font-size: 1.5vi;
        margin-top: 2%;
    }

    .nameHeader {
        margin-top: 4%;
    }

    .teamCardButton {
        margin-top: 0%;
        font-size: 1.4vi;
    }
}

@media only screen and (max-width: 1000px) {
    .background {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        margin-bottom: 5%;
    }

    .nameHeader {
        font-size: 13vi;
    }

    .peoplesImages {
        width: 30%;
        height: auto;
        border-radius: 50%;
    }

    .infoText {
        font-size: 7.5vi;
    }

    .teamCardButtons {
        margin-top: 1%;
    }

    .teamCardButton {
        width: 90%;
        font-size: 7.5vi;
        display: block;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 5%;
    }

    .mobile {
        display: flex;
    }

    .pc {
        display: none;
    }
}